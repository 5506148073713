import AxiosConfig from '../Configuration/AxiosConfig'
import HeaderConfig from '../Configuration/HeaderConfig';
import { CheckInternetConnection, EmptyResponse } from '../Services/UtilitiesServices';

  export function GetForexPageload()
  { 
      return AxiosConfig.post("Forex/GetForexPageload",{}, HeaderConfig());
  }

  export function SubmitForexEnquiry(data)
  { 
      return AxiosConfig.post("Forex/SubmitForexEnquiry", data, HeaderConfig());
  }
